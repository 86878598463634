import { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import Overlayer from "./Overlayer";

const Navigation = (props) => {

    const history = useHistory();
    const location = useLocation();

    const [overlayer_style, setOverlayer_style] = useState("none");
    const [overlayer_render, setOverlayer_render] = useState("");

    const handle_navigation_selection = (type) => {
        console.log(type)
        setOverlayer_render(type);
        if (type) {
            setOverlayer_style("flex");
        }
        else {
            setOverlayer_style("none");
        };
    };

    const handle_city_selection = (city) => {
        props.setCity(city);
        setOverlayer_style("none");
        setOverlayer_render("");
    };


    const ref_token = localStorage.getItem("a_la_rt_fh") + localStorage.getItem("carte_rt_sh");

    const [loading, setLoading] = useState(true);

    const check_session = async (token) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/session", {
                method: "GET",
                "headers": {
                    "a_la_rt": token
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data)
            handle_session_check(data)
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_session_check = (data) => {
        console.log("aaaa")
        if (data.access_token) {
            setLoading(false);
            props.setCity(data.user.city);
            props.setUser(data.user);
            props.setToken(data.access_token);
            // if(location.state) {
            //     history.goBack();
            // }
            // else {
            //     history.replace({ pathname : "/places"})
            // }
        };
        props.setLoading(false)
    };

    useEffect(() => {
        if (ref_token) {
            check_session(ref_token)
        }
        else {
            setLoading(false);
            props.setLoading(false)
        };
    }, [])

    return (
        <div id="navigation_container2">
            {/* <h2 onClick={() => history.push("")}>A LA Carte - UserApp</h2> */}
            <div id="permanent_navigation">
                <div id="navigation_location_container" className="navigation_element_hover" onClick={() => handle_navigation_selection("city")}>
                    <div></div>
                    <h3>{props.city}</h3>
                </div>

                <Link to="/scan" className="scan_link navigation_element_hover">
                    <div></div>
                </Link>
            </div>





            <div id="navigation">
                <Link to={`${props.search_query}`} className="navigation_element_hover">
                    <h3>PLACES</h3>
                </Link>
                <Link to="/events" className="navigation_element_hover">
                    <h3>EVENTS</h3>
                </Link>
                {loading ? null :
                    props.token ?
                        <Link to={`/user/${props.data.id}/orders/active`} id={props.data.sex === "m" ? "male_avatar" : "female_avatar"} className="navigation_element_hover">
                            <div></div>
                        </Link> :
                        <Link to="/login" id="navigation_login" className="navigation_element_hover">
                            <h3>LogIn</h3>
                        </Link>
                }
            </div>

            < Overlayer display={overlayer_style} render={overlayer_render} handle_display={handle_navigation_selection} setCity={handle_city_selection} />
        </div>
    );
};

export default Navigation;