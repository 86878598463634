import { useState, useEffect } from "react";
import LOGO from "../images/logo.png";


const Res_profil_header = ({ rest }) => {

    const { name, type, description } = rest;



    return (
        <div id="rest_profile_header_container">
            <div id="rest_profile_header">
                <div id="profile_header_logo">
                    <img src={LOGO}></img>
                </div>
                <div id="profile_header_details">
                    <h2>{name}</h2> 
                    <h3>{type}</h3> 
                    <p>{description}</p> 
                </div>
                
            </div>
        </div>
    );
};

export default Res_profil_header;