import { useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";

const Login = (props) => {

    const history = useHistory();

    const [rest_name, setRest_name] = useState("")
    const [loading, setLoading] = useState(false);

    const [login_template, setLogin_template] = useState({
        user_name: "User1",
        password: "User1",
    });

    const login = async (table) => {
        setLoading(true);
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/login", {
                method: "POST",
                body: JSON.stringify(login_template),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            handle_login(data)
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_login = (data) => {
        setLoading(false);

        const factor = Math.floor(data.ref_token.length / 2);
        const first_half = data.ref_token.slice(0, factor)
        const second_half = data.ref_token.slice(factor);

        props.setUser(data.user);
        props.setToken(data.access_token);

        localStorage.setItem("a_la_rt_fh", first_half);
        localStorage.setItem("carte_rt_sh", second_half);
        history.goBack();
    };

    const handle_user_input = (e) => {
        const { name, value } = e.target;

        setLogin_template({
            ...login_template,
            [name]: value
        });
    };

    return (
        <div className="overlayer_container">
            <div className="close_overlayer" onClick={() => history.goBack()}></div>
            <div className="overlayer_content_container">
                <h2>LogIn</h2>
                {loading ?
                    < Loading color="white" /> :
                    <div id="login">
                        <input name="user_name" placeholder="User name" onChange={handle_user_input}></input>
                        <input type="password" name="password" placeholder="Password" onChange={handle_user_input}></input>
                        <button onClick={login}>Login</button>
                    </div>
                }
            </div>
        </div>
    );
};

export default Login;