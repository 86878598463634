import { useState, useEffect } from "react";
import Star_IMG from "../images/star_trans.png";

const Res_reviews_comment_card = (props) => {
    const { comment, user_id, creation, review_avg } = props.comment;

    const [date_formated, setDate_formated] = useState({
        date: "",
        time: "",
        day: ""
    });

    const handle_time = (start) => {
        
        const new_date = new Date(start);
        const date = `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`;

        const hours = new_date.getHours() < 10 ? `0${new_date.getHours()}` : new_date.getHours();
        const minutes = new_date.getMinutes() < 10 ? `0${new_date.getMinutes()}` : new_date.getMinutes();
        const time = `${hours}:${minutes}`;

        

        setDate_formated({
            ...date_formated,
            date,
            time,
        });
    };
    

    useEffect(() => {
        handle_time(creation)
    }, []);

    return (
        <div className="res_reviews_comment_card">
            <header>
                <h2>{user_id}</h2>
                <div style={{ backgroundColor: review_avg <= 1.5 ? "#FF5C5C" : review_avg <= 2.5 ? "#FFB65C" : review_avg <= 3.5 ? "#ced41b" : review_avg <= 4.5 ? "#85B4FF" : "#22CC00" }}>
                    <img src={Star_IMG}></img>
                    <h3>{review_avg.toFixed(1)}</h3> 
                </div>
            </header>
            <div className="res_reviews_comment_content">
                <p>{comment}</p>
            </div>
            <div className="res_reviews_comment_date">
                <span>{date_formated.time}, {date_formated.date} </span>
            </div>
        </div>
    );
};

export default Res_reviews_comment_card;