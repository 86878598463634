

const Error_handler = ({ status }) => {
    return (
        <div id="error_handler">
            { status === 404 ? <h2>Not Found</h2> : <h2>Internal error, try again later</h2>}
        </div>
    );
};

export default Error_handler;