

const Order_list_item_card = (props) => {
    const { id, name, quantity, price } = props.item;
    return (
        <div className="order_list_item_card">
            <div>{name}</div>
            <div><span onClick={() => props.handle_order_items("remove", props.item)}>X</span></div>
            <div>
                <button disabled={quantity === 1 ? true : false} onClick={() => props.handle_order_items("reduce", props.item)}>-</button>
                <span>{quantity}</span>
                <button onClick={() => props.handle_order_items("add", props.item)}>+</button>
            </div>
            <div>
                {(quantity * price).toFixed(2)}KM
            </div>
        </div>
    );
};

export default Order_list_item_card;