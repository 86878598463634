import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Sent_IMG from "../images/sent.png";
import Served_IMG from "../images/served.png";
import Review_IMG from "../images/star_o.png";
import Confirmed_IMG from "../images/confirmed.png";
import Bill_IMG from "../images/bill.png";
import Order_evaluation from "./Order_evaluation";


const User_profile_order_card = (props) => {
    const { id, rest_id, name, status, substatus, total, creation, table_no } = props.order;

    const history = useHistory();

    const [order_date, setOrder_date] = useState("");
    const [order_time, setOrder_time] = useState("");
    const [table_update] = useState({
        table_no: table_no,
        rest_id: rest_id,
        status: "free"
    });

    const update_order_status = async (data2) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/order", {
                method: "PATCH",
                body: JSON.stringify(data2),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return true;
        }
        catch (err) {
            console.log(err);
            return false;
        };
    };



    const change_order_status = async (substatus) => {

        let status = "complited";

        if (substatus === "Bill") status = "active";

        const response = await update_order_status({ id, status, substatus });
        if (response) {
            if (substatus === "Bill") {
                props.socket.current.emit("bill_please", {
                    rest_id,
                    order_id: id
                });
            }
            else {
                props.socket.current.emit("cancel_order", {
                    rest_id,
                    order_id: id
                });
                update_table_status(table_update);
            };
            props.update_order_status({ id, substatus })
        }
        else {
            alert("GRESKA");
        };

        // const handler = [...orders_list]
        // handler.find(order => order.id === id).status = status;
        // setOrders_list(handler);
        // update_order_status({ id: id, status: status })
    };

    const update_table_status = async (table) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/tables", {
                method: "PATCH",
                body: JSON.stringify(table),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return true;

        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_order_time = (date) => {
        const TZ_offset = new Date(date).getTimezoneOffset();
        let time = new Date(date).getTime();
        time += -1 * TZ_offset * 60 * 1000
        setOrder_date(new Date(time).toLocaleDateString());
        setOrder_time(new Date(time).toLocaleTimeString());
    };

    useEffect(() => {
        handle_order_time(creation);
    }, []);

    return (
        <div className="user_profile_order_card user_profile_results_card">
            <header>
                <div className="user_profile_result_status" title="Visit restaurant profile">
                    <h2>{substatus === "eval" ? "Review" : substatus}</h2>

                </div>
                <div className="user_profile_result_status_img">
                    <img src={ substatus === "Sent" ? Sent_IMG : substatus === "Confirmed" ? Confirmed_IMG : substatus === "Served" ? Served_IMG : substatus === "Bill" ? Bill_IMG : Review_IMG }></img>
                </div>
                <div className="user_profile_result_time">
                    <h3>{order_date}</h3>
                    <h3>{order_time}</h3>
                </div>
            </header>
            <div >
                <h1 onClick={() => history.push(`restaurant/${rest_id}`)}>{name}</h1>
            </div>
            <div>
                <h4>TOTAL</h4>
                <h2>{total ? total.toFixed(2) : ""}KM</h2>
            </div>

            {substatus === "Sent" ?
                <button onClick={() => change_order_status("Canceled")}>CANCEL ORDER!</button> :
                substatus === "Served" ?
                    <button onClick={() => change_order_status("Bill")}>BILL PLEASE!</button> :
                    substatus === "eval" ?
                        <Order_evaluation order={props.order} /> :
                        ""}

        </div>
    );
};

export default User_profile_order_card;

