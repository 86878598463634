
const Menu_item_card = (props) => {
    const { id, img, name, description, volume, price } = props.item;
    return (
        <div className="menu_item_card">
            <div className="item_image">
                <img src={img} alt={`${name} image`}></img>
            </div>

            <div className="item_details">
                <h3>{name}</h3>
                <p>{description}</p>
                <p>{volume}</p>
            </div>
            {/* {props.session.id == props.id && props.session.table ?  */}
            {props.session.table ?
                <div onClick={() => props.handle_order_items("add", props.item)} className="item_price">
                    <h2>ADD</h2>
                    <p>{price ? price.toFixed(2) : null} KM</p>
                </div> :
                <h2>{price ? price.toFixed(2) : null} KM</h2>
            }
        </div>
    );
};

export default Menu_item_card;