import { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import Loading from "./Loading";
import Error_handler from "./Error_handler";

const Res_reservation = (props) => {

    const params = useParams();
    const history = useHistory();

    const res_end = 21;
    const res_start = 12;
    const res_days = 7;


    const [reservation_data, setReservation_data] = useState({
        today_times: [],
        otherDays_times: [],
        dates: [],
        places: [],
        today_status: true
    });

    const [reservation_template, setReservation_template] = useState({
        sender: 12345,
        rest_id: 1000011,
        user_id: 12345,
        persons: 2,
        status: "active",
        substatus: "Sent",
        event: "0",
        date: "",
        time: "",
        message: "",
    });

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(false);

    const handle_reservation_data = (id) => {
        let event_handler = false;

        if (id !== "0") {
            event_handler = props.events.find(event => event.id === Number(id));
        };
        
        if (event_handler) {
            console.log(event_handler)
            const event_start = new Date(event_handler.start);

            setReservation_data({
                ...reservation_data,
                today_status: true,
                today_times: [{ hour: event_start.getHours(), minutes: event_start.getMinutes() }],
                dates: [{ date: event_start.getDate(), month: event_start.getMonth() + 1, year: event_start.getFullYear() }],
                event: params.event_id
            });

            setReservation_template({
                ...reservation_template,
                date: `${event_start.getFullYear()}-${event_start.getMonth() + 1}-${event_start.getDate()}`,
                time: `${event_start.getHours()}:${event_start.getMinutes() === 0 ? "00" : event_start.getMinutes()}`,
            });
        }
        else {
            history.replace({ pathname: `/restaurant/${params.id}/reserve/0` });

            let reservation_times_today = [];
            let reservation_times_otherDays = [{ hour: res_start, minutes: 0 }];
            let reservation_dates = [];
            let places_handler = [];
            let today_status_handler = true;
            let reservations_start_handler = res_start;

            const reservation_start = new Date(new Date().setHours(new Date().getHours() + 1));

            let year = reservation_start.getFullYear();
            let month = reservation_start.getMonth() + 1;
            let date = reservation_start.getDate();
            let hours = reservation_start.getHours();
            const days_in_month = new Date(year, month, 0).getDate();

            console.log(res_start, res_end, hours)

            if (hours >= res_end) {
                date++
                today_status_handler = false;
            }
            else if (hours <= res_start) {
                today_status_handler = false;
            }
            else {
                if (reservation_start.getMinutes() > 30) {
                    while (hours < res_end - 1) {
                        reservation_times_today.push({ hour: hours + 1, minutes: 0 });
                        reservation_times_today.push({ hour: hours + 1, minutes: 30 });

                        hours++
                    };
                    reservation_times_today.push({ hour: res_end, minutes: 0 });
                }
                else {
                    while (hours < res_end) {
                        reservation_times_today.push({ hour: hours, minutes: 30 });
                        reservation_times_today.push({ hour: hours + 1, minutes: 0 });

                        hours++
                    };
                };
            };

            for (let i = 0; i < res_days; i++) {
                if (date > days_in_month) {
                    date = 1;
                    month++
                    if (month > 12) {
                        month = 1;
                        year++
                    };
                };

                reservation_dates.push({ date, month, year });
                date++
            };

            // Other days times
            while (reservations_start_handler < res_end) {
                reservation_times_otherDays.push({ hour: reservations_start_handler, minutes: 30 })
                reservation_times_otherDays.push({ hour: reservations_start_handler + 1, minutes: 0 })

                reservations_start_handler++
            };

            setReservation_data({
                ...reservation_data,
                dates: reservation_dates,
                otherDays_times: reservation_times_otherDays,
                today_times: reservation_times_today,
                today_status: today_status_handler,
                places: places_handler
            });
        };
    };

    const handle_user_input = (e) => {
        const { name, value } = e.target;

        console.log(name, value)
        if (name === "date") {
            const today = new Date().getDate();
            if (value.endsWith(today)) {
                setReservation_data({
                    ...reservation_data,
                    today_status: true
                });
            }
            else {
                setReservation_data({
                    ...reservation_data,
                    today_status: false
                });
            };
        };

        if (name === "event") {
            handle_reservation_data(value)
        };

        setReservation_template({
            ...reservation_template,
            [name]: value,
        });

        console.log(reservation_template)
    };

    const verify_reservation = () => {

        alert("dodaj confirmation i verify")
        update_reservation()
    };


    const update_reservation = async () => {

        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/reservations", {
                method: "POST",
                body: JSON.stringify(reservation_template),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });
            // socket.current.emit("new_visit", params.id);
            if (!response.ok) throw new Error();
            const data = await response.json();
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const get_events = async () => {
        try {
            let response;

            response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/events/?city=&limit=10&id=${params.id}`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            setEvents(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };


    useEffect(() => {
        if (props.events.length) {
            handle_reservation_data(params.event_id);
            setLoading(false);
        };
    }, [props.events])

    return (
        <div id="rest_reservation_container">
            {loading ? <Loading /> :
                <div id="rest_reservation_form">
                    <header>
                        <h3>Reserve for an event?</h3>
                        <select name="event" defaultValue={params.event_id} onChange={(e) => handle_user_input(e)}>
                            <option value="0">General reservation</option>
                            {props.events.map(event => <option key={event.id} value={event.id}>{`${event.title}`}</option>)}
                        </select>
                    </header>
                    <div id="rest_reservation_form_input">

                        <div>
                            <h4>Persons</h4>
                            <input type="number" value={reservation_template.persons} name="persons" onChange={(e) => handle_user_input(e)}></input>
                        </div>

                        <div>
                            <h4>Date</h4>
                            <select name="date" value={reservation_template.date} onChange={(e) => handle_user_input(e)}>
                                {reservation_data.dates.map(date => <option value={`${date.year}-${date.month}-${date.date}`}>{`${date.date}/${date.month}/${date.year}`}</option>)}
                            </select>

                        </div>

                        <div>
                            <h4>Time</h4>
                            <select name="time" value={reservation_template.time} onChange={(e) => handle_user_input(e)}>
                                {reservation_data.today_status ?
                                    reservation_data.today_times.map(time => <option value={`${time.hour}:${time.minutes === 0 ? "00" : time.minutes}`}>{`${time.hour}:${time.minutes === 0 ? "00" : time.minutes}`}</option>) :
                                    reservation_data.otherDays_times.map(time => <option>{`${time.hour}:${time.minutes === 0 ? "00" : time.minutes}`}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <textarea name="message" onChange={(e) => handle_user_input(e)} placeholder="Message.."></textarea>
                    <button onClick={verify_reservation}>SEND</button>
                </div>
            }
        </div>
    );
};

export default Res_reservation;