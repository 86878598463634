import { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Link } from 'react-router-dom';
import User_profile_order_card from "./User_profile_order_card";
import User_profile_reservation_card from "./User_profile_reservation_card";
import Loading from "./Loading";
import io from "socket.io-client";

const User_profile = (props) => {

    const params = useParams();
    const history = useHistory();
    console.log(params)
    const socket = useRef();

    // const [user_orders, setUsers_order] = useState({
    //     orders: [],
    //     limit: 5
    // });

    const [user_orders, setUsers_order] = useState([]);

    const [render_data, setRender_data] = useState({
        data: [],
        limit: 5
    })

    const [reserv, setReserv] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        socket.current = io.connect("https://alacartee.herokuapp.com");

        socket.current.on("changed_status", () => {
            get_user_orders();
        });

        return () => socket.current.disconnect();
    });

    const id = 12345


    const get_user_orders = async (status) => {
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/users/orders/?id=${id}&status=${status}&limit=${user_orders.limit}&type=user`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            // data.reverse();
            setUsers_order(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const get_user_reservations = async (status) => {
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/users/reservations/?id=${id}&status=${status}&limit=${user_orders.limit}&type=user`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            // data.reverse();
            setReserv(data);
            console.log(data);

        }
        catch (err) {
            console.log(err)
        };
    };

    const get_data = async (type, status) => {
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/users/${type}/?id=${id}&status=${status}&limit=${render_data.limit}&type=user`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            // data.reverse();
            handle_data_update(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_data_update = (data) => {

        setRender_data({
            ...render_data,
            data
        });

        setLoading(false);
        
    };


    const show_more_orders = () => {
        setUsers_order({
            ...user_orders,
            limit: user_orders.limit + 5
        });
    };

    const update_order_status = (data) => {
        const { id, substatus } = data;
        alert("DODAJ CONFIRMATION");
        if (substatus === "Bill") {
            const handler = [...render_data.data] 
            console.log(handler)
            handler.find(order => order.id === id).substatus = substatus;

            setRender_data({
                ...render_data,
                data : handler
            });
        }
        else {
            setRender_data({
                ...render_data,
                data : render_data.data.filter(order => order.id !== id)
            });
        };
    };

    const handle_page_load = () => {
        if(props.city) {
            let params_handler = {
                id: "",
                type: params.type,
                status: params.status,
            };
    
            // saljemo token i ref token (iz storagea a_la_f + carte_s) saljemo na server i dobijemo nazad id i uporedjuemo ga sa props.id
    
            if(params.type !== "reservations" && params.type !== "orders") {
                params_handler.type = "orders"
            };
    
            if (params.status !== "complited" && params.status !== "active") {
                params_handler.status = "active"
            };
    
            history.replace({ pathname : `/user/${params.id}/${params_handler.type}/${params_handler.status}`});
    
            get_data(params_handler.type, params_handler.status);
        };
    };

    const logOut = () => {
        setLoading(true);
        localStorage.removeItem("a_la_rt_fh");
        localStorage.removeItem("carte_rt_sh");
        props.setToken("");
        history.replace({ pathname: "/places"})
    };

    useEffect(() => {
        setLoading(true);
        setRender_data({
            ...render_data,
            data: []
        });

        handle_page_load();
        
    }, [params.type, params.status]);

    useEffect(() => {
        handle_page_load();
        props.setLoading(false);
    }, [])

    return (
        <div className="user_profile">
            <div id="user_profile_navigation" className="sub_navigation">
                <div className="drop_menu">
                    <h3>MY <br/> ORDERS</h3>
                    <div className="drop_navigation ">
                        <Link to={`/user/${id}/orders/active`} className="navigation_element_hover">
                            <h3>ACTIVE</h3>
                        </Link>
                        <Link to={`/user/${id}/orders/complited`} className="navigation_element_hover">
                            <h3>COMPLITED</h3>
                        </Link>
                    </div>
                </div>
                <div className="drop_menu">
                    <h3>MY <br/> RESERVATIONS</h3>
                    <div className="drop_navigation ">
                        <Link to={`/user/${id}/reservations/active`} className="navigation_element_hover">
                            <h3>ACTIVE</h3>
                        </Link>
                        <Link to={`/user/${id}/reservations/complited`} className="navigation_element_hover">
                            <h3>COMPLITED</h3>
                        </Link>
                    </div>
                </div>
                <div id="user_profile_logout" className="navigation_element_hover" onClick={logOut}>
                    <h3>LogOut</h3>
                </div>
            </div>
            
                { loading ?
                    < Loading /> :
                    <>
                        {render_data.data.length ?
                            params.type === "orders" ?
                                render_data.data.map(order => < User_profile_order_card key={order.id} order={order} socket={socket} update_order_status={update_order_status} />) :
                                render_data.data.map(reservation => < User_profile_reservation_card key={reservation.id} reservation={reservation} />) :
                            <p>No {params.status === "active" ? "active" : "complited"} {params.type === "orders" ? "orders" : "reservations"}!</p>}
                    </>
                    }
        </div>
    );
};

export default User_profile;