import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Order_list_item_card from "./Order_list_item_card";

const Order_list = (props) => {

    const params = useParams();
    const textArea = useRef();

    const [message_status, setMessage_status] = useState(false);

    const handle_message_display = (status) => {
        if (status) {
            textArea.current.style.height = "100px";
            textArea.current.style.padding = "10px";
            textArea.current.style.color = "black";
            textArea.current.focus();
        }
        else {
            
            textArea.current.style.height = "0px";
            textArea.current.style.padding = "0px";
            textArea.current.style.color = "white";
            textArea.current.blur();
        };
        setMessage_status(!message_status);
    };

    const send_order_validation = () => {
        // handle_message_display(false);
        props.send_order();
        // alert("CONFIRMATION")
    };

    // useEffect(() => {
        
    // }, [])

    return (
        <div className="order_list">
            <h2>YOUR ORDER</h2>
            <div className="order_list_navigation">
                <button>TABLE {props.table}</button>
                <button onClick={() => handle_message_display(message_status ? false : true)}>{message_status ? "CLOSE MESSAGE" : "ADD MESSAGE"}</button>
            </div>
            {
                props.order.map(item => < Order_list_item_card key={item.id} item={item} handle_order_items={props.handle_order_items} />)
            }
            <div className="order_list_total">
                <div>TOTAL = </div>
                <div>{props.total.toFixed(2)}KM</div>
            </div>

            <textarea name="message" onChange={props.handle_user_input} ref={textArea} placeholder="Additional info" defaultValue=", Thanks."></textarea>
            <button disabled={props.order.length === 0 ? true : false} onClick={send_order_validation}>SEND</button>
        </div>
    );
};

export default Order_list;