

const Loading = (props) => {
    return (
        <div id="loading">
            <h1 style={{color: props.color? props.color : "black"}}>Loading..</h1>
        </div>
    );
};

export default Loading;