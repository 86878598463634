import { useState, useEffect, useRef } from "react";
import Res_reviews_comment_card from "./Res_reviews_comment_card";
import Loading from "./Loading";
import Star_IMG from "../images/star_trans.png";

const Res_reviews = (props) => {

    const [reviews, setReviews] = useState({});
    const [loading, setLoading] = useState(true);

    const get_rest_reviews = async () => {
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/evaluation/?id=${props.id}&limit=5`, {
                method: "GET",
            });

            if (!response.ok) throw new Error({ message: "Nije dobro" });

            const data = await response.json();
            // data.reverse();
            setReviews(data[0]);
            setLoading(false);
            console.log(data);

        }
        catch (err) {
            console.log(err)
        };
    };

    useEffect(() => {
        get_rest_reviews();
    }, [])

    return (
        <div id="rest_reviews_container">
            { loading ? < Loading /> :
                <>
                    
                        <div id="rest_reviews_sticky">
                            <div id="rest_reviews_sticky_title">
                                <img src={Star_IMG}></img>
                                <div>
                                    <h2>{reviews.review_avg ? reviews.review_avg.toFixed(1) : 0.0}</h2>
                                    <span>({reviews.count})</span>
                                </div>
                            </div>
                            <div className="rest_reviews_sticky_type">
                                <h3>MENU</h3>
                                <h2>{reviews.menu_avg ? reviews.menu_avg.toFixed(1) : 0.0}</h2>
                            </div>
                            <div className="rest_reviews_sticky_type">
                                <h3>SERVICE</h3>
                                <h2>{ reviews.service_avg ? reviews.service_avg.toFixed(1) : 0.0} </h2>
                            </div>
                            <div className="rest_reviews_sticky_type">
                                <h3>AMBIENCE</h3>
                                <h2>{ reviews.ambience_avg ? reviews.ambience_avg.toFixed(1) : 0.0} </h2>
                            </div>
                        </div>
                    


                    <div id="rest_reviews_comments_container">
                        {reviews.comments.length ? reviews.comments.map(comment => < Res_reviews_comment_card comment={comment} />) : <h2>No Comments to display</h2>}
                    </div>
                </>

            }
        </div>
    );
};

export default Res_reviews;