import { useState, useEffect } from "react";
import Res_card from "./Res_card";
import { useHistory, useLocation } from "react-router-dom";
import UseDebounce from "./UseDebounce";
import UseLogger from "./UseLogger";
import decode from "jwt-decode";

import Loading from "./Loading";
import userEvent from "@testing-library/user-event";

const Home_page = (props) => {

    const qs = require('query-string');
    const history = useHistory();
    const location = useLocation();

    const [restaurants, setRestaurants] = useState([]);
    const [query, setQuery] = useState(qs.parse(`${location.search}`));

    const handleRouterURL = (value) => {
        props.setSearch_query(`/places?${qs.stringify(value)}`)
        history.replace({ pathname: `/places`, search: `${qs.stringify(value)}` });
    };

    const handle_filter_selection = (e) => {
        const query_handler = { ...query };
        delete query_handler.name;

        const { type } = e.target;
        if (type === "checkbox") {
            const { checked } = e.target;
            let open = "";
            if (checked) open = true;
            else open = "";

            query_handler.open = open;
        }
        else {
            const { value } = e.target;
            query_handler.type = value;
        };

        setQuery(query_handler);
        handleRouterURL(query_handler);
        get_restaurants(query_handler);
    };

    const handle_search_input = (e) => {
        const { value } = e.target;
        if (!value) get_restaurants("");
        setQuery({
            name: value
        });
    };

    const search_debounce = UseDebounce(query.name, 1000);

    const get_restaurants = async (query) => {
        console.log(qs.stringify(query))
        try {
            let response;

            response = await fetch(`https://alacartee.herokuapp.com/api/restaurants?city=${props.city}&${qs.stringify(query)}`, {
                method: "GET",
            });

            if (!response.ok) throw new Error({ message: "Nije dobro" });

            const data = await response.json();
            setRestaurants(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_page_load = () => {
        let query_handler = qs.parse(location.search);
        // if (!query_handler.city) query_handler.city = props.city;

        setQuery(query_handler);
        get_restaurants(query_handler);
        handleRouterURL(query_handler);
    };

    useEffect(() => {

        handle_page_load();
    }, [props.city]);

    useEffect(() => {
        if (search_debounce.name) {
            // search_debounce.city = props.city;
            handleRouterURL(search_debounce);
            get_restaurants(search_debounce);
        }
    }, [search_debounce]);

    const ref_token = localStorage.getItem("a_la_rt_fh") + localStorage.getItem("carte_rt_sh");



    const provjera = async (a_token) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/provjera", {
                method: "GET",
                "headers": {
                    "a_la_at": a_token,
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data)
            // handle_session_check(data)
        }
        catch (err) {
            console.log(err)
        };
    };



    
    
// FUNKCIJE ZA VALIDACIJU
    
    const send_request = async (a_token) => {
        if (a_token) {
            const check = check_token(a_token);
            if (check) {
                provjera(a_token);
            }
            else {
                const new_token = await refresh_token(ref_token);
    
                console.log("new_token")
                provjera(new_token.new_token);
                props.setToken(new_token.new_token);
            };
        }
        else if (ref_token){
            const new_token = await refresh_token(ref_token);
            provjera(new_token.new_token);
            props.setToken(new_token.new_token);
        }
        else {
            // set Error 
        }
    };

    const check_token = (token) => {
        try {
            const date = new Date(0);
            const decoded = decode(token);
            date.setUTCSeconds(decoded.exp);
            return date.valueOf() > new Date().valueOf();
        }
        catch (err) {
            return false;
        };
    };

    const refresh_token = async (r_token) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/token", {
                method: "GET",
                "headers": {
                    "a_la_rt": r_token,
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data)
            return data;
            // handle_session_check(data)
        }
        catch (err) {
            console.log(err)
        };
    };


    return (
        <div id="home_page">
            <div id="filter_restaurants" className="sub_navigation">
                <div>
                    <input name="name" type="search" placeholder="Find Restaurant.." value={query.name ? query.name : ""} onChange={handle_search_input}></input>
                    <h4>OR</h4>
                </div>
                <div>
                    <select value={query.type ? query.type : ""} name="type" onChange={handle_filter_selection}>
                        <option value="">Select type</option>
                        <option value="Bar">Bar</option>
                        <option value="Grill">Grill</option>
                        <option value="Traditional">Traditional</option>
                    </select>
                    <div>
                        <span>JUST OPEN</span>
                        <input checked={query.open ? true : false} name="open" type="checkbox" onChange={handle_filter_selection}></input>
                    </div>
                </div>
            </div>

            {/* <button onClick={() => send_request(props.a_token)}>PROVJERI</button> */}
            {restaurants.length > 0 ?
                <div className="show_restaurants">
                    {restaurants.map(rest => < Res_card key={rest.id} rest={rest} />)}
                </div> : <h3>No results</h3>
            }
        </div>
    );
};

export default Home_page;