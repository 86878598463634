import { useState, useEffect, useRef } from "react";
import Star_IMG from "../images/star_trans.png";

const Order_evaluation = (props) => {

    const { id, rest_id, user_id } = props.order;

    const [evaluation, setEvaluation] = useState({
        id,
        rest_id,
        user_id,
        menu: 0,
        service: 0,
        ambience: 0,
        comment: ""
    });

    const [average, setAverage] = useState(2.14);

    const [evaluation_style, setEvaluation_style] = useState({
        menu: ["", "", "", "", ""],
        service: ["", "", "", "", ""],
        ambience: ["", "", "", "", ""],
    });

    const handle_evaluation_input = (type, mark) => {
        const { menu, service, ambience } = evaluation;
        let divider = 1;
        let sum = mark;

        if (type === "menu") {
            if (service > 0) {
                divider += 1
                sum += service
            }
            if (ambience > 0) {
                divider += 1
                sum += ambience
            };
        }
        else if (type === "service") {
            if (menu > 0) {
                divider += 1
                sum += menu
            }
            if (ambience > 0) {
                divider += 1
                sum += ambience
            };
        }
        else if (type === "ambience") {
            if (menu > 0) {
                divider += 1
                sum += menu
            }
            if (service > 0) {
                divider += 1
                sum += service
            };
        }
        else {
            return;
        };

        setEvaluation({
            ...evaluation,
            [type]: mark
        });

        setAverage((sum / divider).toFixed(1));
        handle_evaluation_selection_style(type, mark);
    };


    const handle_evaluation_hover_style = (e, type, mark) => {
        let style_handler = []

        if (e.type === "mouseover") {
            for (let i = 0; i < 5; i++) {
                if (i < mark) {
                    style_handler.push(`order_evaluation_hover_${mark}`);
                }
                else {
                    style_handler.push("");
                };
            };

            setEvaluation_style({
                ...evaluation_style,
                [type]: style_handler
            });
        }
        else {
            handle_evaluation_selection_style(type, evaluation[type]);
        };
    };

    const handle_evaluation_selection_style = (type, mark) => {
        let style_handler = []

        for (let i = 0; i < 5; i++) {
            if (i < mark) {
                style_handler.push(`order_evaluation_selection_${mark}`);
            }
            else {
                style_handler.push("");
            };
        };

        setEvaluation_style({
            ...evaluation_style,
            [type]: style_handler
        });
    };

    const handle_comment_input = (e) => {
        const { value } = e.target

        setEvaluation({
            ...evaluation,
            comment: value
        });
    }

    const update_evaluation = async () => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/evaluation", {
                method: "POST",
                body: JSON.stringify(evaluation),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return true;

        }
        catch (err) {
            console.log(err)
        };
    };

    // const validate_review = (status) => {

    //     if ( status === "send") {
    //         const { menu, service, ambience } = evaluation;

    //         if(menu === 0 || service === 0 || ambience === 0) {
                
    //         }
    //         else {
    
    //         };
    //     }
    //     else {

    //     };
    // };

    return (
        <div className="order_evaluation_container">
            <header>
                <h3>We would love to hear Your impressions about Your visit</h3>
                <div className="order_evaluation_average" style={{ backgroundColor: average <= 1.5 ? "#FF5C5C" : average <= 2.5 ? "#FFB65C" : average <= 3.5 ? "#dce21a" : average <= 4.5 ? "#85B4FF" : "#22CC00" }}>
                    <img src={Star_IMG}></img>
                    <span>{average !== 2.14 ? average : ""}</span>
                </div>
            </header>
            <div className="order_evaluation_mark_container">
                <div className="order_evaluation_title">
                    <h3>MENU</h3>
                </div>
                <div className="order_evaluation_mark">
                    <div className={evaluation_style.menu[0]} onMouseOver={(e) => handle_evaluation_hover_style(e, "menu", 1)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "menu")} onClick={() => handle_evaluation_input("menu", 1)}></div>
                    <div className={evaluation_style.menu[1]} onMouseOver={(e) => handle_evaluation_hover_style(e, "menu", 2)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "menu")} onClick={() => handle_evaluation_input("menu", 2)}></div>
                    <div className={evaluation_style.menu[2]} onMouseOver={(e) => handle_evaluation_hover_style(e, "menu", 3)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "menu")} onClick={() => handle_evaluation_input("menu", 3)}></div>
                    <div className={evaluation_style.menu[3]} onMouseOver={(e) => handle_evaluation_hover_style(e, "menu", 4)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "menu")} onClick={() => handle_evaluation_input("menu", 4)}></div>
                    <div className={evaluation_style.menu[4]} onMouseOver={(e) => handle_evaluation_hover_style(e, "menu", 5)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "menu")} onClick={() => handle_evaluation_input("menu", 5)}></div>
                </div>
            </div>
            <div className="order_evaluation_mark_container">
                <div className="order_evaluation_title">
                    <h3>SERVICE</h3>
                </div>
                <div className="order_evaluation_mark">
                    <div className={evaluation_style.service[0]} onMouseOver={(e) => handle_evaluation_hover_style(e, "service", 1)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "service")} onClick={() => handle_evaluation_input("service", 1)}></div>
                    <div className={evaluation_style.service[1]} onMouseOver={(e) => handle_evaluation_hover_style(e, "service", 2)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "service")} onClick={() => handle_evaluation_input("service", 2)}></div>
                    <div className={evaluation_style.service[2]} onMouseOver={(e) => handle_evaluation_hover_style(e, "service", 3)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "service")} onClick={() => handle_evaluation_input("service", 3)}></div>
                    <div className={evaluation_style.service[3]} onMouseOver={(e) => handle_evaluation_hover_style(e, "service", 4)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "service")} onClick={() => handle_evaluation_input("service", 4)}></div>
                    <div className={evaluation_style.service[4]} onMouseOver={(e) => handle_evaluation_hover_style(e, "service", 5)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "service")} onClick={() => handle_evaluation_input("service", 5)}></div>
                </div>
            </div>
            <div className="order_evaluation_mark_container">
                <div className="order_evaluation_title">
                    <h3>AMBIENCE</h3>
                </div>
                <div className="order_evaluation_mark">
                    <div className={evaluation_style.ambience[0]} onMouseOver={(e) => handle_evaluation_hover_style(e, "ambience", 1)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "ambience")} onClick={() => handle_evaluation_input("ambience", 1)}></div>
                    <div className={evaluation_style.ambience[1]} onMouseOver={(e) => handle_evaluation_hover_style(e, "ambience", 2)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "ambience")} onClick={() => handle_evaluation_input("ambience", 2)}></div>
                    <div className={evaluation_style.ambience[2]} onMouseOver={(e) => handle_evaluation_hover_style(e, "ambience", 3)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "ambience")} onClick={() => handle_evaluation_input("ambience", 3)}></div>
                    <div className={evaluation_style.ambience[3]} onMouseOver={(e) => handle_evaluation_hover_style(e, "ambience", 4)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "ambience")} onClick={() => handle_evaluation_input("ambience", 4)}></div>
                    <div className={evaluation_style.ambience[4]} onMouseOver={(e) => handle_evaluation_hover_style(e, "ambience", 5)} onMouseLeave={(e) => handle_evaluation_hover_style(e, "ambience")} onClick={() => handle_evaluation_input("ambience", 5)}></div>
                </div>
            </div>

            <div className="order_evaluation_comment">
                <textarea placeholder="Add comment.." onChange={handle_comment_input}></textarea>
            </div>
            <div className="order_evaluation_navigation">
                <button>SKIP</button>
                <button  disabled={ evaluation.menu === 0 || evaluation.service === 0 || evaluation.ambience === 0 ? true : false} onClick={update_evaluation}>SEND</button>
            </div>
        </div>
    );
};

export default Order_evaluation;