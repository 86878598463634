import { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';

const Event_card = (props) => {
    const {id, img, title, priority, start, type_1, type_2, name, rest_id} = props.event;

    const history = useHistory();

    const [start_formated, setStart_formated] = useState({
        date: "",
        time: "",
        day: ""
    });

    const handle_time = (start) => {
        
        const new_date = new Date(start);
        const date = `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`;

        const hours = new_date.getHours() < 10 ? `0${new_date.getHours()}` : new_date.getHours();
        const minutes = new_date.getMinutes() < 10 ? `0${new_date.getMinutes()}` : new_date.getMinutes();
        const time = `${hours}:${minutes}`;

        const day = getDay_name(new_date.getDay());

        setStart_formated({
            ...start_formated,
            date,
            time,
            day
        });
    };



    const getDay_name = (dayIndex) => {
        const currentDay = new Date().getDay();
        if (currentDay === dayIndex) {
            return "Today";
        }
        else if (currentDay + 1 === dayIndex || currentDay + 1 === dayIndex + 7) {
            return "Tomorrow";
        }
        else {
            switch (true) {
                case dayIndex === 0:
                    return "Sunday";
                case dayIndex === 1:
                    return "Monday";
                case dayIndex === 2:
                    return "Tuesday";
                case dayIndex === 3:
                    return "Wednesday";
                case dayIndex === 4:
                    return "Thursday";
                case dayIndex === 5:
                    return "Friday";
                case dayIndex === 6:
                    return "Saturday";
                default:
                    break;
            };
        }; 
    };

    const handle_event_reservation = () => {
        history.push(`/restaurant/${rest_id}/reserve/${id}`)
    };

    useEffect(() => {
        if(start) {
            handle_time(start);
        }
    }, [start])

    return (
        <div className="event_card_container">
            <div className="event_card_img" style={{backgroundImage: `url(${img})`}}></div>
            <div className="event_card_description">
                {/* priority: {priority} <br/>
                #{type_1} <br/>
                {type_2 ? `#${type_2}` : ""} */}
                
                
                <h2>{title}</h2>
                <p>{props.event.description ? props.event.description : null}</p>
            </div>
            <button onClick={handle_event_reservation}>RESERVE</button>
            <div className="event_card_info">
                <div><h2>{name}</h2></div>
                <div><h3>{start_formated.day} <br/> {start_formated.date} <br/> {start_formated.time}h</h3></div>
            </div>
        </div>
    );
};

export default Event_card;