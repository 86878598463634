import "./style/style.css";
import { useState, useEffect, useRef } from "react";
import Places from "./components/Places";
import Res_profile from "./components/Res_profile";
import User_profile from "./components/User_profile";
import Naviation from "./components/Navigation";
import Naviation2 from "./components/Navigation2";
import Events from "./components/Events";
import Login from "./components/Login";
import Welcome_page from "./components/Welcome_page";
import QR_scanner from "./components/QR_scanner";
import Loading from "./components/Loading";
import UseSession from "./components/UseSession";
import io from "socket.io-client";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

let socket;

function App() {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("")
  const [city, setCity] = useState(localStorage.getItem("a_la_city"));
  const [search_query, setSearch_query] = useState("/places");
  const [session, setSession] = useState({
    id: "2000001",
    table: "7"
  });

  
//   useEffect(() => {
//     socket = io.connect("https://alacartee.herokuapp.com");

//     return () => {
//       io.disconnect("https://alacartee.herokuapp.com");
//       socket = null;
//     }
// }, []);

  return (
    < Router>
      <div className="App">
        <Naviation2 city={city} setCity={setCity} setSession={setSession} setLoading={setLoading} search_query={search_query} token={token} data={{ id: user.id, sex: user.sex }} setUser={setUser} setToken={setToken} />
        <Switch>
          < Route exact path="/" >
            < Welcome_page setCity={setCity} setUser={setUser} setToken={setToken} a_token={token} />
          </Route>
          < Route exact path="/scan">
            < QR_scanner setSession={setSession} setCity={setCity} />
          </Route>
          < Route exact path="/places">
            {city ?
              < Places search_query={search_query} setSearch_query={setSearch_query} city={city} a_token={token} setToken={setToken} /> :
              <Redirect push to={{ pathname: "/" }} />}
          </Route>
          < Route exact path="/restaurant/:id/:type?/:event_id?">
            {city ?
              < Res_profile session={session} a_token={token} user={user}/> :
              < Redirect push to={{ pathname: "/", state: { redirect: true } }} />}
          </Route>
          < Route exact path="/user/:id/:type?/:status?">
            {loading ?
              <Loading /> :
              token ?
                < User_profile city={city} setLoading={setLoading} setToken={setToken} user={user}/> :
                <Redirect push to={{ pathname: "/login" }} />}
          </Route>
          < Route exact path="/events">
            {city ?
              < Events /> :
              <Redirect push to={{ pathname: "/", state: { redirect: true } }} />}
          </Route>
          < Route exact path="/login">
            {/* {!user.token ?
              < Login setUser={setUser} /> :
              <Redirect to={{ pathname: "/"}} />} */}
            < Login setUser={setUser} setToken={setToken} />
          </Route>
          < Route>
            {city ?
              <Redirect push to={{ pathname: "/places" }} /> :
              <Redirect push to={{ pathname: "/" }} />}
          </Route>

          {/* {city ?
            < Events /> :
            <Redirect to={{ pathname: "/" }} />} */}

        </Switch>
      </div>
    </Router>
  );
}

export default App;
