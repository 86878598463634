
import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import User_profile_reservation_card_message from "./User_profile_reservation_card_message";
import Sent_IMG from "../images/sent.png";
import Confirmed_IMG from "../images/confirmed.png";
import Chat_IMG from "../images/chat.png";

import Avatar_m_IMG from "../images/avatar_m.png"
import Hours_IMG from "../images/hours.png";




const User_profile_reservation_card = (props) => {
    const { id, user_id, rest_id, name, persons, event_id, event_name, substatus, creation, start, messages } = props.reservation

    const sender_id = 12345;

    const message_conatiner = useRef();

    
    const params = useParams();
    const [date_formated, setDate_formated] = useState({
        start: "",
        creation: "",
        day: ""
    });

    const [reservation_messages, setReservation_messages] = useState(messages ? messages : []);

    const [message_template, setMessage_template] = useState({
        message: "",
        sender: sender_id,
        reservation_id: id
    });

    const handle_user_input = (e) => {
        const message_handler = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        setMessage_template({
            ...message_template,
            message: message_handler 
        });

        console.log(message_template)
    };

    const handle_times = () => {

        const creation_formated = format_time(creation);
        const start_formated = format_time(start);

        setDate_formated({
            ...date_formated,
            start: start_formated,
            creation: creation_formated
        });
    };


    const format_time = (start) => {

        const new_date = new Date(start);
        const date = `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`;

        const hours = new_date.getHours() < 10 ? `0${new_date.getHours()}` : new_date.getHours();
        const minutes = new_date.getMinutes() < 10 ? `0${new_date.getMinutes()}` : new_date.getMinutes();
        const time = `${hours}:${minutes}`;


        return {
            date,
            time
        };
    };

    // const history = useHistory();

    // const [order_date, setOrder_date] = useState("");
    // const [order_time, setOrder_time] = useState("");
    // const [table_update] = useState({
    //     table_no: table_no,
    //     rest_id: rest_id,
    //     status: "free"
    // });

    const handle_message_send = () => {
        alert("confirmation");

        send_message(message_template);
    };

    const handle_message_update = (data) => {
        setReservation_messages(data);
        setMessage_template({
            ...message_template,
            message: ""
        });
        scroll_to_bottom();
        // setLoading(false)
    };

    const send_message = async (message) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/reservations_msg", {
                method: "POST",
                body: JSON.stringify(message),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            
            handle_message_update(data);
        }
        catch (err) {
            console.log(err);
            
        };
    };

    const scroll_to_bottom = () => {
        const scroll_amount = message_conatiner.current.scrollHeight - message_conatiner.current.clientHeight;
        message_conatiner.current.scrollTo(0, scroll_amount);
    }


    // const update_table_status = async (table) => {
    //     try {
    //         const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/tables", {
    //             method: "PATCH",
    //             body: JSON.stringify(table),
    //             "headers": {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json"
    //             }
    //         });

    //         if (!response.ok) throw new Error();

    //         const data = await response.json();
    //         console.log(data);
    //         return true;

    //     }
    //     catch (err) {
    //         console.log(err)
    //     };
    // };

    // const handle_order_time = (date) => {
    //     const TZ_offset = new Date(date).getTimezoneOffset();
    //     let time = new Date(date).getTime();
    //     time += -1 * TZ_offset * 60 * 1000
    //     setOrder_date(new Date(time).toLocaleDateString());
    //     setOrder_time(new Date(time).toLocaleTimeString());
    // };

    useEffect(() => {
        handle_times();
        scroll_to_bottom();
    }, []);

    return (
        <div className="user_profile_reservation_card user_profile_results_card">
            <header>
                <div className="user_profile_result_status" title="Visit restaurant profile">
                    <h2>{substatus}</h2>

                </div>
                <div className="user_profile_result_status_img">
                    <img src={Chat_IMG} alt="Status"></img>
                </div>
                <div className="user_profile_result_time">
                    <h3>{date_formated.creation.date}</h3>
                    <h3>{date_formated.creation.time}</h3>
                </div>
            </header>

            <div className="user_profile_reservation_card_info">
                <div>
                    <h2>{name}</h2>
                </div>
                <div>
                    <img src={Hours_IMG}></img>
                    <h4>{date_formated.start.time}</h4>,
                    <p>{date_formated.start.date}</p>
                </div>
                <div>
                    <h3>EVENT: </h3>
                    <p>{event_name ? event_name : "General reservation"}</p>
                </div>
                <div>
                    <img src={Avatar_m_IMG}></img>
                    <p>{persons}</p>
                </div>
                {/* <div>
                    <button>CANCLE </button>
                </div> */}
            </div>
            <div className="user_profile_reservation_card_chat_container">
                <div ref={message_conatiner} className="user_profile_reservation_card_chat">
                    {reservation_messages.length ? reservation_messages.map(message => < User_profile_reservation_card_message key={message.id} message={message} name={name}/> ) : "no messages"}
                </div>
                
                <div className="user_profile_reservation_card_chat_input_container">
                        <textarea placeholder="Message.." value={message_template.message} onChange={handle_user_input}></textarea>
                        <button onClick={handle_message_send}>SEND</button>
                </div>
            </div>

        
            {/* <header>
                <div onClick={() => history.push(`restaurant/${rest_id}`)} className="user_profile_order_card_rest" title="Visit restaurant profile">
                    <h2>Confirmed{ }</h2>
                </div>
                <img src={Confirmed_IMG}></img>
                <div className="user_profile_order_card_creation">
                    <h3>{order_date}</h3>
                    <h3>{order_time}</h3>
                </div>
            </header>
            <h3>{name}</h3>
            <div className="user_profile_order_card_total">
                <h3>TOTAL: {total.toFixed(2)}KM</h3>
            </div> */}
            {/* <Order_evaluation order={props.order}/> */}
            {/* {substatus === "Sent" ?
                <button onClick={() => change_order_status("Canceled")}>CANCEL ORDER!</button> :
                substatus === "Served" ?
                    <button onClick={() => change_order_status("Bill")}>BILL PLEASE!</button> :
                    substatus === "eval" ?
                    <Order_evaluation order={props.order}/> :
                    ""} */}

        </div>
    );
};

export default User_profile_reservation_card;
