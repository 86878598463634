import React, { useState, useEffect } from 'react';

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState({name: value});
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue({
          ...debouncedValue,
          name: value});
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value] 
  );

  return debouncedValue;
};