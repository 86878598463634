import { useState, useEffect } from "react";
import Event_card from "./Event_card";

const Events = () => {

    const [events, setEvents] = useState([]);
    const [query, setQuery] =useState({
        city: "banja luka",
        limit: 6,
        type: ""
    });

    const get_events = async (type) => {
        try {
            let response;

            response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/events/?city=${query.city}&limit=${query.limit}&type=${type}`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            setEvents(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_navigation_selection = (type) => {
        setQuery({
            ...query,
            type 
        });

        get_events(type);
    };

    useEffect(() => {
        get_events("");
    }, []);

    return (
        <div id="events_container">
            <div id="events_navigation" className="sub_navigation">
                <div onClick={() => handle_navigation_selection("")}> <p>#all</p></div>
                <div onClick={() => handle_navigation_selection("music")}><p>#music</p></div>
                <div onClick={() => handle_navigation_selection("promotion")}><p>#promo</p></div>
                <div onClick={() => handle_navigation_selection("sport")}><p>#sport</p></div>
            </div>
            <div className="events">
                {events.map(event => < Event_card key={event.id} event={event}/>)}
            </div>
        </div>
    );
};

export default Events;