import { useState, useEffect } from "react";
import UseDebounce from "./UseDebounce";
import { useLocation, useHistory, Link } from "react-router-dom";



const Select_city = (props) => {

    const history = useHistory();

    const [cities, setCities] = useState("");
    const [user_input, setUser_input] = useState("")

    const get_filter_data = async (value) => {
        console.log(value)
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/filterdata/?city=${value}`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            if (data.length) {
                setCities(data.slice(0, 3));
            }
            else {
                setCities([{ city: "No results" }]);
            }

            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const search_debounce = UseDebounce(user_input, 800);

    const handle_user_input = (e) => {
        const { value } = e.target;

        setCities("")

        setUser_input(value);
    };

    const handle_city_selection = (city) => {
        if (city) {
            props.setCity(city);
            localStorage.setItem("a_la_city", city)
        };
    };

    useEffect(() => {
        if (search_debounce.name) {
            get_filter_data(search_debounce.name);
        }
    }, [search_debounce]);

    return (
        <div id="city_selection_container">
            <input placeholder="Enter city.." onChange={handle_user_input}></input>
            <div id="city_selection_search_results">
                {
                    cities ?
                        cities.length ?
                            cities.map((city, i) => <div key={i} className="navigation_element_hover" onClick={() => handle_city_selection(city.city)}> <h3>{city.city}</h3> </div>) :
                            <div><h3>No results</h3></div> : null
                }
            </div>
        </div>
    );
};

export default Select_city;