import { useParams, Switch, Route, Link, useHistory } from 'react-router-dom';


const Res_profile_navigation = (props) => {

    return (
        <div id="rest_profile_navigation_container">
            <Link to={`/restaurant/${props.id}/menu`}>
                <span>MENU</span>
            </Link>
            <Link to={`/restaurant/${props.id}/events`}>
                <span>EVENTS</span>
            </Link>
            <Link to={`/restaurant/${props.id}/reviews`}>
                <span>RATING {props.eval.avg ? props.eval.avg.toFixed(1) : 0}{`(${props.eval.count ? props.eval.count : 0})`}</span>
            </Link>
            <Link to={`/restaurant/${props.id}/reserve/0`}>
                <span>RESERVE</span>
            </Link>
        </div>
    );
};

export default Res_profile_navigation;