import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import QrReader from 'react-qr-scanner'

const QR_scanner = (props) => {

    const history = useHistory();

    const [qr, setQr] = useState(true);
    const delay = 200;

    const handle_qr_scan = (data) => {
        if (data) {
            setQr(false);
            const data_parsed = JSON.parse(data.text);
            props.setCity(data_parsed.city);
            localStorage.setItem("a_la_city", data_parsed.city)
            props.setSession({
                table: data_parsed.table,
                id: data_parsed.id
            });
            
            history.push(`/restaurant/${data_parsed.id}/menu`)
        };
    };

    const handleError = (err) => {
        console.error(err)
    };

    const previewStyle = {
        height: "100%",
        width: "100%",
    };

    return (
        <div className="overlayer_container">
            <div className="close_overlayer" onClick={() => history.goBack()}></div>
            <div className="overlayer_content_container">
                <h2>Scan QR code</h2>
                <div id="overlayer_scan">
                    {qr ?
                        <QrReader
                            delay={delay}
                            style={previewStyle}
                            onError={handleError}
                            onScan={handle_qr_scan}
                        /> : null}
                </div>
            </div>
        </div>
    );
};


export default QR_scanner;