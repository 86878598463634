import { useState, useEffect } from "react";
import { useLocation, useHistory, Link, Redirect } from "react-router-dom";
import Select_city from "./Select_city";
import Loading from "./Loading";


const Welcome_page = (props) => {

    const history= useHistory();
    const location = useLocation();

    const ref_token = localStorage.getItem("a_la_rt_fh") + localStorage.getItem("carte_rt_sh");

    const [loading, setLoading] = useState(true);

    const check_session = async (token) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/users/session", {
                method: "GET",
                "headers": {
                    "a_la_rt": token
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data)
            handle_session_check(data)
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_session_check = (data) => {
        if (data.access_token) {
            props.setCity(data.user.city);
            props.setUser(data.user);
            props.setToken(data.access_token);
            if(location.state) {
                history.goBack();
            }
            else {
                history.replace({ pathname : "/places"})
            };
        };
    };

    const handle_city_selection = (city) => {
        props.setCity(city);
        if(location.state) {
            history.goBack();
        }
        else {
            history.replace({ pathname : "/places"});
        };
    };

    useEffect(() => {
        setLoading(false);
    }, [])

    return (
        <div id="welcome_container" className="overlayer_container">
            <div id="welcome" className="overlayer_content_container">
                {loading ?
                    < Loading color="white" /> :
                    <>
                        <h2>WELCOME</h2>
                        

                        <h3>Looking for good place?</h3>
                        <div id="welcome_city_select">
                            < Select_city setCity={handle_city_selection} />
                        </div>
                        <h3>Allready at place?</h3>
                        <Link to="/scan" className="scan_link navigation_element_hover " style={{ margin: "0px", width: "100px", height: "100px" }}>
                            <div></div>
                        </Link>
                    </>}
            </div>
        </div>
    );
};

export default Welcome_page;