import { useState } from "react";


const Menu_category = (props) => {
    const aaa = `http://www.my-templates.space/images/${props.category}.png`

    return (
        <div onClick={() => props.handle_menu_filter(props.category)} id="menu_category">
        <img src={aaa}></img>
        <h2>{props.category.toUpperCase()}</h2>
        </div>
    );
};

export default Menu_category;