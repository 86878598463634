import { useState, useEffect, useRef } from "react";
import Menu from "./Menu";
import Res_profil_header from "./Res_profile_header";
import Res_profile_navigation from "./Res_profile_navigation";
import Event_card from "./Event_card";
import Res_reviews from "./Res_reviews";
import Res_reservation from "./Res_reservation";
import Loading from "./Loading";
import Error_handler from "./Error_handler";
import io from "socket.io-client";
import { useParams, Switch, Route, useHistory, Redirect } from 'react-router-dom';


const Res_profile = (props) => {

    const params = useParams();
    const socket = useRef();
    const history = useHistory();

    const [rest, setRest] = useState({});
    const [displayed, setDisplayed] = useState("menu");
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const handle_error = (response) => {
        if (!response.ok) {
            setError(response.status);
            throw new Error(response.statusText);
        }
        else {
            setLoading(false);
            return response;
        };
    };

    const get_rest = async () => {
        try {
            const response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/profile/${params.id}`, {
                method: "GET",
            });
            const checken_response = handle_error(response);
            const data = await checken_response.json();
            console.log(data);
            setRest(data[0]);
        }
        catch (err) {
            console.log(err)
        };
    };

    const get_events = async () => {
        try {
            let response;

            response = await fetch(`https://alacartee.herokuapp.com/api/restaurants/events/?city=&limit=10&id=${params.id}`, {
                method: "GET",
            });

            if (!response.ok) throw new Error("Nije dobro");

            const data = await response.json();
            setEvents(data);
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_navigation_selection = (value) => {
        setDisplayed(value)
    };


    // const cancle_order = () => {
    //     socket.emit("cancle_order", "1000011")
    // };

    // const handle_input = (e) => {
    //     const { name, value } = e.target;

    //     setSocket_order_template({ ...socket_order_template, [name]: value });
    //     setOrder_template({ ...order_template, [name]: value });
    // };

    useEffect(() => {
        socket.current = io.connect("https://alacartee.herokuapp.com", { query: { rest: params.id } });

        return () => socket.current.disconnect();
    });

    const update_rest_visits = async () => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/visit", {
                method: "POST",
                body: JSON.stringify({
                    "visitor_id": 1000000,
                    "rest_id": params.id
                }),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });
            socket.current.emit("new_visit", params.id);
            if (!response.ok) throw new Error();
            const data = await response.json();
            console.log(data);
        }
        catch (err) {
            console.log(err)
        };
    };

    const handle_page_load = () => {
        let params_handler = {
            id: "",
            type: params.type,
        };


        // saljemo token i ref token (iz storagea a_la_f + carte_s) saljemo na server i dobijemo nazad id i uporedjuemo ga sa props.id

        if (params.type !== "menu" && params.type !== "events" && params.type !== "reviews" && params.type !== "reserve") {
            history.replace({ pathname: `/restaurant/${params.id}/menu` });
        }
        else {
            if (params.type === "reserve") {
                if (!params.event_id) {
                    history.replace({ pathname: `/restaurant/${params.id}/reserve/0` });
                };
            };
        };

        get_rest();
        update_rest_visits();
        get_events();
    };

    useEffect(() => {
        handle_page_load();
    }, []);

    return (
        <>
            { error ?
                <Error_handler status={error}/> :
                loading ? <Loading /> :
                    <div id="rest_profile">
                        < Res_profil_header rest={rest} />
                        < Res_profile_navigation id={params.id} handle_navigation_selection={handle_navigation_selection} eval={{ count: rest.eval_count, avg: rest.eval_avg }} />
                        <>
                            <Switch>
                                < Route path="/restaurant/:id/menu">
                                    < Menu id={params.id} socket={socket} menu={rest.menu} rest_status={rest.status} session={props.session} a_token={props.a_token} user={props.user}/>
                                </Route>
                                < Route path="/restaurant/:id/events">
                                    <div style={{ width: "80%" }} className="events">
                                        {events.map(event => < Event_card key={event.id} event={event} />)}
                                    </div>
                                </Route>
                                < Route path="/restaurant/:id/reviews">
                                    < Res_reviews id={params.id} />
                                </Route>
                                < Route path="/restaurant/:id/reserve/:event_id">
                                    < Res_reservation events={events} />
                                </Route>
                            </Switch>
                        </>

                        <footer>
                            <p>{rest.address}</p> <br />
                            <p>{rest.city}</p> <br />
                            <p>{rest.open} - {rest.close}</p> <br />
                            <p>{rest.email}</p> <br />
                            <p>{rest.tell}</p> <br />
                        </footer>
                    </div>

            }
        </>
    );
};

export default Res_profile;