import { useState, useEffect } from "react";
import QR_scanner from "./QR_scanner";
import Select_city from "./Select_city";

const Overlayer = (props) => {
    return (
        <div style={{ display: props.display }} className="overlayer_container">
            <div className="close_overlayer" onClick={() => props.handle_display("")}></div>
            <div id="overlayer" className="overlayer_content_container">
                {
                    props.render === "city" ?
                        <>
                            <h2>Select city</h2>
                            <div id="overlayer_city">
                                < Select_city setCity={props.setCity} />
                            </div>
                        </> : null}
            </div>
        </div>
    );
};

export default Overlayer;