import { useState, useEffect } from "react"

const User_profile_reservation_card_message = (props) => {
    const { message, sender, creation } = props.message;

    const [date_formated, setDate_formated] = useState("");



    const format_time = (start) => {

        const new_date = new Date(start);
        const date = `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`;

        const hours = new_date.getHours() < 10 ? `0${new_date.getHours()}` : new_date.getHours();
        const minutes = new_date.getMinutes() < 10 ? `0${new_date.getMinutes()}` : new_date.getMinutes();
        const time = `${hours}:${minutes}`;

        setDate_formated({
            date,
            time
        })
    };

    useEffect(() => {
        format_time(creation);
    }, [])

    const id = 12345;
    return (
        <div className={sender === id ? "my_message" : "notMy_message"}>
                <h3>{sender !== id ? props.name : ""}</h3> 
                <p>{message}</p>
                <span>{date_formated.date}, {date_formated.time}</span>
        </div>
    );
};

export default User_profile_reservation_card_message;