import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Bar_IMG from "../images/bar.png";
import Res_IMG from "../images/rest.png";
import Table_IMG from "../images/table.png";
import Location_IMG from "../images/location.png";
import Hours_IMG from "../images/hours.png";

const Res_card = (props) => {
    const { name, type, id, tables, open, close, city } = props.rest;
    const history = useHistory();

    const naziv = "Bar, Grill, Traditional"
    const aaa = "Krupski Slapovi"

    const send_message = () => {
        history.push(`/restaurant/${id}/menu`);
    };

    return (
        <div className="res_card" onClick={send_message}>
            <div>
                <h2>{name}</h2>
            </div>

            <div>
                <img src={type === "Bar" ? Bar_IMG : Res_IMG}></img>
                {type}
            </div>
            
            <div >
                <p style={{ backgroundColor: props.rest.status ? "#88e276" : "#ffadad" }}>{props.status ? "OPEN" : props.rest.status ? "OPEN" : "CLOSED"}</p>
            </div>

            <div>
                <img src={Location_IMG}></img>
                {city}
            </div>
            
            <div>
                <img src={Table_IMG}></img>
                {tables}
            </div>
            
            <div>
                <img src={Hours_IMG}></img>
                {open}<br />{close}
            </div>


        </div>
    );
};

export default Res_card;