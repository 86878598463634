import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Menu_category from "./Menu_category";
import Menu_item_card from "./Menu_item_card";
import Order_list from "./Order_list";

const Menu = (props) => {

    const alhocol_alowenc = 18

    const history = useHistory();

    const [menu, setMenu] = useState({
        drinks: [],
        food: []
    });

    const [order, setOrder] = useState([]);

    const [order_template, setOrder_template] = useState({
        user_id: 12345,
        rest_id: props.id,
        status: "active",
        substatus: "Sent",
        table_no: props.session.table,
        message: ""
    });

    const [socket_order_template, setSocket_order_template] = useState({
        user_id: 12345,
        rest_id: props.id,
        table_no: props.session.table,
        total: 0,
        status: "active",
        substatus: "Sent",
        message: "",
        list: []
    });

    const [table_update, setTable_update] = useState({
        table_no: props.session.table,
        rest_id: props.id,
        status: "busy"
    });

    const handle_order_items = (status, item) => {
        if (status === "add") {
            const item_exists_check = order.some(order_item => order_item.id === item.id);
            if (!item_exists_check) {
                item.quantity = 1;
                setOrder(prev => [...prev, item])
            }
            else {
                const items = [...order]
                items.find(order_item => order_item.id === item.id).quantity += 1;
                setOrder(items);
            };
            setSocket_order_template({
                ...socket_order_template,
                total: socket_order_template.total + item.price
            });
        }
        else if (status === "reduce") {
            const items = [...order]
            items.find(order_item => order_item.id === item.id).quantity -= 1;
            setOrder(items);

            setSocket_order_template({
                ...socket_order_template,
                total: socket_order_template.total - item.price
            });
        }
        else if (status === "remove") {
            setSocket_order_template({
                ...socket_order_template,
                total: socket_order_template.total - (item.price * item.quantity)
            });

            setOrder(prev => prev.filter(prev_item => prev_item.id !== item.id));
        };
    };

    const handle_user_input = (e) => {
        const { name, value } = e.target;

        setSocket_order_template({
            ...socket_order_template,
            [name]: value
        });

        setOrder_template({
            ...order_template,
            [name]: value
        });
    };


    const sort_menu = (data) => {

        let food = data.filter(item => item.type === "food");
        let drinks = data.filter(item => item.type === "drinks");

        let food_category = [...new Set(food.map(item => item.category))]
        let drinks_category = [...new Set(drinks.map(item => item.category))];

        setMenu({
            ...menu,
            food: food_category,
            drinks: drinks_category
        });
    };


    // ODAVDE

    const update_order_inDB = async () => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/order", {
                method: "POST",
                body: JSON.stringify(order_template),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return data;
        }
        catch (err) {
            console.log(err)
        };
    };

    const update_order_items_inDB = async (order_list) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/order-items", {
                method: "POST",
                body: JSON.stringify(order_list),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return true;

        }
        catch (err) {
            console.log(err)
        };
    };

    const update_table_status = async (table) => {
        try {
            const response = await fetch("https://alacartee.herokuapp.com/api/restaurants/tables", {
                method: "PATCH",
                body: JSON.stringify(table),
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            if (!response.ok) throw new Error();

            const data = await response.json();
            console.log(data);
            return true;
        }
        catch (err) {
            console.log(err)
        };
    };

    const send_order = async () => {

        if (order.length) {
            const order_id = await update_order_inDB();

            let order_list = [];
            if (order_id) {
                order.forEach(item => {
                    order_list.push([order_id, item.id, item.quantity, item.price]);
                });

                const order_list_update = await update_order_items_inDB(order_list);

                if (order_list_update) {
                    // table_update.table_no = order_template.table_no;
                    await update_table_status(table_update);

                    socket_order_template.id = order_id;
                    socket_order_template.list = order;
                    socket_order_template.creation = Date.now();

                    props.socket.current.emit("order", socket_order_template);
                };
            };
            setOrder([]);
            history.push("/user/12345")
        }
        else {
            alert("Niste nista narucili");
        };



    };

    const getAge = (dateString) => {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    

    const send_order_validation = () => {
        if(props.user.id) {
            const age2 = getAge(props.user.birthday)
            console.log(age2)
            if (!props.a_token) {
                history.push("/login");
            }
            else if (age2 < alhocol_alowenc) {
                // if alkohol
                alert("NARUCILI STE ALKOHOL, u pratnji sam odrasle osobe, dodati za restoran granicu godina za alkohol i dodati u meni da li je nesto alkohol")
                return;
            }
            else {
                
            }
            send_order();
        }
        else {
            // history.push("login");
        };
    };


    useEffect(() => {
        if (props.menu) {
            sort_menu(props.menu);
            handle_type_selection("drinks");
        };
    }, [props.menu]);

    //  OBRADA MENIJA

    const [subMenu, setSubMenu] = useState(true);

    const [displayed_type, setDisplayed_type] = useState("drinks");

    const [filtered_menu_items, setFiltered_menu_items] = useState([]);

    const [filter_level, setFilter_level] = useState({
        drinks: "category",
        food: "category"
    });

    const [navigation_categories, setNavigation_categories] = useState({
        drinks: [],
        food: []
    });


    // KAD KLIKNEMO FOOD ili DRINKS
    const handle_type_selection = (type) => {
        const filter_handler = props.menu.filter(item => item.type === type);
        setNavigation_categories({
            ...navigation_categories,
            [type]: []
        });

        setFilter_level({
            ...filter_level,
            [type]: "category"
        });
        setDisplayed_type(type);

        check_subcategory("category", filter_handler);
    };


    //KAD KLIKNEMO NESTO IZ NAVIGACIJE
    const handle_navigation_select = (data) => {
        const { type, level, value } = data;

        const filter_handler = props.menu.filter(item => item[level] === value);
        setDisplayed_type(type);

        if (level === "category") {
            setNavigation_categories({
                ...navigation_categories,
                [type]: navigation_categories[type].slice(0, 1)
            });
        };

        check_subcategory("subcategory", filter_handler);
    };


    // KAD KLIKNEMO NESTO IZ REZULTATA
    const handle_menu_filter = (value) => {
        //pravimo objekat za navigaciju
        const nav_element_handler = {
            type: displayed_type,
            level: filter_level[displayed_type],
            value
        };

        const filter_handler = props.menu.filter(item => item[filter_level[displayed_type]] === value);

        // UPDATE NAVIGACIJU
        if (displayed_type === "food") {
            setNavigation_categories({
                ...navigation_categories,
                [displayed_type]: [nav_element_handler, ...navigation_categories.food]
            });
        }
        else {
            setNavigation_categories({
                ...navigation_categories,
                [displayed_type]: [...navigation_categories.drinks, nav_element_handler]
            });
        };

        if (filter_level[displayed_type] === "category") {
            setFilter_level({
                ...filter_level,
                [displayed_type]: "subcategory"
            });
        };

        check_subcategory("subcategory", filter_handler);
    };


    const check_subcategory = (condition, data) => {
        const subcategory_check = [...new Set(data.map(item => item[condition]))];
        console.log(subcategory_check, condition, data)
        if (subcategory_check.length > 1) {
            setSubMenu(true);
            setFiltered_menu_items(subcategory_check);
        }
        else {
            setFiltered_menu_items(data);
            setSubMenu(false);
        };
    };

    useEffect(() => {
        console.log(props.session)
    }, [props.session])

    return (
        <div id="menu_container">
            {/* <div id="menu" style={{ width: props.session.id == props.id && props.session.table  ? "calc(100% - 350px)" : "100%" }}> */}
            <div id="menu">
                <div id="menu_navigation_container">
                    <div id="menu_navigation_drinks">
                        <div onClick={() => handle_type_selection("drinks")}>
                            <div></div>
                            <div></div>
                            <span>DRINKS</span>
                        </div>
                        {
                            navigation_categories.drinks.map((item, i) => <div key={i} onClick={() => handle_navigation_select(item)}><div></div> <div></div> <span>{item.value.toUpperCase()}</span></div>)
                        }

                    </div>
                    <div id="menu_navigation_food">
                        {
                            navigation_categories.food.map((item, i) => <div key={i} onClick={() => handle_navigation_select(item)}><div></div> <div></div> <span>{item.value.toUpperCase()}</span></div>)
                        }
                        <div onClick={() => handle_type_selection("food")}>
                            <div></div>
                            <div></div>
                            <span>FOOD</span>
                        </div>
                    </div>
                </div>
                <div id="menu_filter_results">
                    {subMenu ?
                        filtered_menu_items.map(item => < Menu_category key={item} category={item} handle_menu_filter={handle_menu_filter} />) :
                        filtered_menu_items.map(item => < Menu_item_card key={item.id} item={item} handle_order_items={handle_order_items} session={props.session} id={props.id}  />)
                    }
                </div>
            </div>
            {/* {props.session.id == props.id && props.session.table ? */}
            {props.session.table ?
                < Order_list order={order} send_order={send_order_validation} handle_order_items={handle_order_items} total={socket_order_template.total} handle_user_input={handle_user_input} table={props.session.table}/>
                : null}
        </div>
    );
};

export default Menu;